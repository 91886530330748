<template>
    <div class="upload-container">
      <h2>斑点机器人作业上传</h2>
      <h2>文件命名方式</h2>
      <h2>用 djks3_ 开头+作业日期+学生姓名</h2>
      <h2>例如：张三上传2024年6月实操作业</h2>
      <h2>作业名：djks3_202406张三</h2>
      <div class="file-upload">
        <label for="file-input" class="custom-file-upload">
          <i class="fas fa-cloud-upload-alt"></i> 选择一个文件
        </label>
        <input type="file" id="file-input" accept=".rar,.zip"  @change="onFileChange" />
        <div v-if="selectedFile" class="file-details">
          <p><strong>文件名:</strong> {{ selectedFile.name }}</p>
          <p><strong>文件大小:</strong> {{ (selectedFile.size / 1024).toFixed(2) }} KB</p>
          <img v-if="isImage" :src="filePreview" alt="File Preview" class="file-preview" />
        </div>
        <button @click="uploadFile" :disabled="!selectedFile" class="upload-btn">
          上传
        </button>
      </div>
      <div v-if="uploadProgress !== null" class="progress-bar">
        <div class="progress" :style="{ width: uploadProgress + '%' }"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        selectedFile: null,
        filePreview: null,
        uploadProgress: null,
      };
    },
    computed: {
      isImage() {
        return this.selectedFile && this.selectedFile.type.startsWith('image/');
      },
    },
    methods: {
      onFileChange(event) {
        this.selectedFile = event.target.files[0];
        if (this.isImage) {
          this.filePreview = URL.createObjectURL(this.selectedFile);
        } else {
          this.filePreview = null;
        }
      },
      async uploadFile() {
        if (!this.selectedFile) {
          alert("请先选择一个文件!");
          return;
        }
  
        let formData = new FormData();
        formData.append('file', this.selectedFile);
  
        try {
          let response = await fetch('https://www.spotrobot101.com:5001/index', {
            method: 'POST',
            body: formData,
          });
  
          if (response.ok) {
            this.uploadProgress = null;
            alert("作业上传成功!");
          } else {
            alert("作业上传失败");
          }
        } catch (error) {
          console.error("Error:", error);
          alert("作业上传错误");
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .upload-container {
    max-width: 400px;
    margin: 0 auto; /* Center horizontally */
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center text and elements inside */
  }
  
  h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .file-upload {
    position: relative;
    margin-bottom: 20px;
  }
  
  .custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .custom-file-upload i {
    margin-right: 10px;
  }
  
  input[type="file"] {
    display: none;
  }
  
  .file-details {
    margin-top: 15px;
    text-align: left; /* Align text to the left */
  }
  
  .file-preview {
    max-width: 100%;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  .upload-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .upload-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-top: 20px;
    height: 10px;
  }
  
  .progress {
    height: 100%;
    background-color: #007bff;
    border-radius: 5px;
    transition: width 0.4s ease;
  }
  </style>
  